.navbar {
  top: 0;
  background-color: black;
  display: flex;
  height: 75px;
  align-items: center;
  width: 100%;
  position: fixed;
  margin-left: -20px;
  justify-content: space-between;
  z-index: 100;
}

.navicon {
  object-fit: contain;
  width: 150px;
  height: 80px;
  margin-top: -4px;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin-left: 46%;
}


.user_icon {
  width: 70px;
  height: 70px;
  align-items: center;
  padding: 13px;
  margin-top: -6px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 1.7rem;
  background: none;
  margin-top: 20px;
}

.nav-menu {
  background-color: #000000;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position:absolute;
  top: 0;
  margin-top: 15px;
  margin-left: -10px;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background: #314755; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #26a0da,
    #346280
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #000000;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.username{
  margin-top: -14px;
  margin-left: 20px;
  margin-right: 20px;
  color: #f1f0f0;
  font-size: small;
  font-family: "Nunito", sans-serif;
}
.Sidebar_name{
  font-size: 0.5rem;
}