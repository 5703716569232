@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  
  margin:0;
  
}

.Admin__Title{
    margin-top: 100px;
    font-family: "Roboto", sans-serif;
}
.navbar {
  top: 0;
  background-color: black;
  display: flex;
  height: 75px;
  align-items: center;
  width: 100%;
  position: fixed;
  margin-left: -20px;
  justify-content: space-between;
  z-index: 100;
}

.navicon {
  object-fit: contain;
  width: 150px;
  height: 80px;
  margin-top: -4px;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin-left: 46%;
}


.user_icon {
  width: 70px;
  height: 70px;
  align-items: center;
  padding: 13px;
  margin-top: -6px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 1.7rem;
  background: none;
  margin-top: 20px;
}

.nav-menu {
  background-color: #000000;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position:absolute;
  top: 0;
  margin-top: 15px;
  margin-left: -10px;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background: #314755; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #000000;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.username{
  margin-top: -14px;
  margin-left: 20px;
  margin-right: 20px;
  color: #f1f0f0;
  font-size: small;
  font-family: "Nunito", sans-serif;
}
.Sidebar_name{
  font-size: 0.5rem;
}

body{
    margin:20px;
}

.Error_Home{
	border-radius: 20px;
	border: 1px solid #449ff5;
	background-color: #128cbd;
	font-size: 20px;
	font-weight: 600;
	padding: 12px 30px !important;
	letter-spacing: 1px;
	/* text-transform: uppercase; */
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in ;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
	font-family: 'Nunito', sans-serif; 
	cursor: pointer;  
    margin-top: -30%;
    z-index: 100;
}

.Error_Home > button{
	margin-top: -30%;
}

.error_main{
  z-index: -100;
  height: 400px;
  object-fit: contain;
  margin-top: 40px;
}

h2{
    text-align: center;
    align-content: center;
    justify-content: center;
}
*{
	box-sizing: border-box;
}

.logo{
    height: 100px;
    margin: auto;
    margin-left: 45%;
    z-index: -1;
}

h1 {
	font-weight: bold;
	margin: 0;
}

h2 {
	text-align: center;
}

p {
	font-size: 22px;
	font-weight: 100;
	line-height: 29px;
	letter-spacing: 0.4px;
	margin: 20px 0 30px;
}

span {
	font-size: 22px;
}

a {
	color: rgb(6, 14, 20);
	font-size: 22px;
	text-decoration: none;
	margin: 15px 0;
}


button {
	border-radius: 20px;
	border: 1px solid #449ff5;
	background-color: #2da1b1;
	color: #FFFFFF;
	font-size: 20px;
	font-weight: 500;
	padding: 12px 45px !important;
	letter-spacing: 1px;
	/* text-transform: uppercase; */
    transition: -webkit-transform 80ms ease-in !important ;
    transition: transform 80ms ease-in !important ;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in !important ;
	font-family: 'Nunito', sans-serif; 
	cursor: pointer;   
}

button:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

input {
	background-color: #eee !important;
	border-radius: 20px !important;
	border: 1px solid #ffffff !important;
	padding: 12px 15px !important;
	margin: 8px 0 !important;
    width: auto ;
	font-family: 'Nunito', sans-serif !important;
    font-size: 3vh !important;
}
.Signup_button{
	margin-top: 5px;
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right-panel-active .sign-in-container {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
	opacity: 1;
	overflow: hidden;
	z-index: 5;
	-webkit-animation: show 0.6s;
	        animation: show 0.6s;
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	-webkit-transform: translateX(-100%);
	        transform: translateX(-100%);
}

.overlay {
	background: #358aa0;
	background: linear-gradient(to right, #3ca2af, #309dbe);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	-webkit-transform: translateX(0);
  	        transform: translateX(0);
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
	-webkit-transform: translateX(50%);
	        transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	transition: -webkit-transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out;
	transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

.overlay-left {
	-webkit-transform: translateX(-20%);
	        transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.overlay-right {
	right: 0;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	-webkit-transform: translateX(20%);
	        transform: translateX(20%);
}
* {
  padding: 0px;
  margin: 0px;
}


ul.social {
    position: relative;
    display: flex !important;
    -webkit-transform: rotate(25deg) skew(deg);
            transform: rotate(25deg) skew(deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
 }
 ul.social li.social {
   position: relative;
   list-style: none;
   width: 50px;
   height: 50px;
   margin: -20px 22px;
   margin-top: -10px;
   cursor: pointer;
   border-radius: 50px;
 }
.error{
	color: red !important;
}
 ul.social li.social span{
   position: absolute;
   top: 0;
   /* left: 0; */
   width: 100% ;
   height: 100%;
   display: flex !important;
   /* background: #2da1b1; */
   justify-content: center;
   align-items: center;
   color: #fff;
   font-size: 30px !important;
   transition: 1.5s ease-out;
   margin-left: 0px ;
   margin-top: 5px;
   border-radius: 10px;
 }
 ul.social li.social:hover span {
   z-index: 1000;
   transition: .3s;
   color: #fff;
   /* box-shadw: -1px 1px 1px rgba(0, 0, 0, .5) !important; */
 }
 
 
 /* ul.social li.social:hover span:nth-child(5){
   transform: translate(20px, -20px);
   background: #9e2166;
   opacity: 1;
   border-radius: 50px;
 } */
 /* ul.social li.social:hover span:nth-child(4){
   transform: translate(30px, -30px);
   opacity: .8;
 }
 ul.social li.social:hover span:nth-child(3){
   transform: translate(20px, -20px);
   opacity: .6;
 } */
 /* ul.social li.social:hover span:nth-child(2){
   transform: translate(10px, -10px);
   opacity: .8 !important;
   border-radius: 50px;
 } */
 /* ul.social li.social:hover span:nth-child(1){
   transform: translate(5px, -5px);
   opacity: .2;
 } */
 /* ul.social li.social:nth-child(1):hover span{
   background:	#4285F4;
   opacity: 1;
   border-radius: 10px;
 }
 ul.social li.social:nth-child(2):hover span{
   background: #3b5998;
   opacity: 1;
   border-radius: 10px;
 } */
 /* ul.social li.social:nth-child(3):hover span{
	background: #f09433; 
	background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
	background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
	background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
	opacity: 1;
	border-radius: 10px;
  } */
* {
  margin: 0;
  font-family: "Nunito", sans-serif !important  ;
}
body {
  text-align: center;
  overflow: hidden;
  font-family: "Nunito", sans-serif;
  background: rgb(255, 255, 255); /* fallback for old browsers */
}
.order {
  margin-top: 15px;
}
.card {
  margin: 20px !important;
  height: 280px !important;
  width: 280px !important;
  background: #314755; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  font-size: 4vh;
  font-weight: 300;
  text-align: center;
  color: white;
  box-shadow: 10px 12px 18px -6px rgba(37, 37, 37, 0.753);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: 150ms;
  -webkit-transform: perspective(10000px) rotateY(0)
    translateY(0);
          transform: perspective(10000px) rotateY(0)
    translateY(0);
  -webkit-transform: perspective(10000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
          transform: perspective(10000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  /* display: flex;
  flex-direction: column; */
}
.card > img {
  width: 285px;
  border-radius: 50%;
  margin-top: -2px;
  margin-left: -1px;
  object-fit: cover;
  box-shadow: 10px 12px 18px -6px rgba(37, 37, 37, 0.753);
  z-index: -1;
}

.card:hover {
  --translate-y: -8px;
  box-shadow: 0 0 5px 1px rgb(165, 166, 167) !important;
}

.card.flip {
  --rotate-y: 180deg;
  background: #314755; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: #2d5d69;
  font-size: 4vh;
  font-weight: 300;
  font-family: "Nunito", sans-serif;
  text-align: center;
  font-weight: bold;
}

.card .front {
  border-radius: 50%;
  object-fit: cover;
  width: 55%;
  z-index: -1;
  margin-top: -32px;
  text-align: center;
  justify-content: center;
  position: fixed;
}

.card .back {
  border-radius: 50%;
  object-fit: cover;
  position: fixed;
  width: 115%;
  height: 100%;
  z-index: -1;
  margin-left: -10px;
  margin-top: 0px;
  object-fit: contain !important;
  /* margin-bottom: 10px; */
  text-align: center;
}

.card .back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.card:hover {
  --translate-y: -1px;
  box-shadow: 0 0 5px 1px rgb(165, 166, 167) !important;
}

.card .front,
.card .back {
  position: absolute;
  padding: 1rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.cards_container {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.grid {
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  height: 100% !important;
  width: 100% !important;
  overflow: auto;
  position: fixed;
  min-height: 110%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 55px !important;
  margin-bottom: 70% !important;
  margin-left: -10px !important;
  /* margin: 10px; */
  justify-content: center;
  text-align: center;
  /* margin: auto; */
  -webkit-animation: backInDown;
          animation: backInDown; /* referring directly to the animation's @keyframe declaration */
  -webkit-animation-duration: 2s;
          animation-duration: 2s; /* don't forget to set a duration! */
  scroll-padding: 400px;
  padding-bottom: 160px !important;
  z-index: -2;
  padding-top: 115px !important;
  /* padding-right: 5px; */
  /* padding-left: 20px; */
}

.grid::-webkit-scrollbar-track {
  scrollbar-width: none;
  box-shadow: inset 0 0 4px white;
}

.grid > div {
  flex-basis: calc(25% - 40px);
}

.details {
  color: rgb(0, 0, 0);
  position: relative;
  margin-top: 20px;
}

.number {
  z-index: 2;
  bottom: 0px;
  /* position: fixed; */
  color: white;
}

.msg {
  max-width: 210px;
  min-height: 1px;
  margin-top: 120px;
  margin-left: 38px;
  font-family: "Nunito", sans-serif;
  /* object-fit: contain; */
  color: #ffffff;
  z-index: 2;
  font-size: 4vh;
  text-align: center;
  justify-content: center;
  align-content: center;
  font-weight: 600;
}

.img_number {
  position: absolute;
  bottom: 0;
  color: white;
  font-weight: 700;
  font-size: 4.1vh;
}

.hidden {
  visibility: hidden;
}
.visible {
  visibility: visible;
}

.delete_card {
  margin: 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  height: 282px;
  width: 87%;
  background: rgba(20, 59, 97, 0.993);
  background: linear-gradient(to bottom, #1d8bbe, #042f49);
  border-radius: 50% !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 4vh;
  font-weight: 300;
  text-align: center;
  margin-top: -1px;
  margin-left: 17px;
  z-index: 3;
}

h2 {
  font-size: 2.3vh;
  font-weight: 600;
  min-width: 210px;
  margin-left: -36px;
  position: fixed;
  margin-top: -45px;
}
.delete_card.front {
  position: fixed;
  padding: 1rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 50%;
  object-fit: cover;
  width: 37%;
  z-index: 10;
}

.delete_card.back {
  /* position: absolute; */
  padding: 1rem;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 50%;
  /* object-fit: cover; */
  width: 38%;
  z-index: 10;
  background-color: white;
}

.delete_icons {
  margin-left: -105px;
  z-index: 10;
  color: #e0e0e0;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
  /* display: table-row; */
  display: flex;
  position: fixed;
}

.del_text {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  align-content: space-between;
  margin-left: -10px;
  color: #fcfcfc;
  margin-top: 5px;
  position: fixed;
}

.delete_img_card {
  z-index: 1;
  margin-left: -218px;
  margin-top: 142px;
}
.outer {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}
.outer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
.outer .inner {
  z-index: 2;
}

/* Style buttons */
.btn {
  /* height: 0%; */
  width: 30%;
  display: flex;
  flex-direction: row;
  background: none;
  align-items: center;
  margin-left: 8px;
}

.multiple_hidden {
  visibility: hidden;
}
.multiple_visible {
  visibility: visible;
  font-size: 12px;
}
.timer {
  margin-top: -20px;
  margin-left: -7px;
}

.home_queue {
  /* background: rgba(142, 176, 228, 0.3); */
  background: none;
  -webkit-backdrop-filter: blur(0px);
          backdrop-filter: blur(0px);

  box-shadow: 8px 8px 5px rgba(0, 0, 0, 0.3),
    inset 5px -5px 0px 5px rgba(200, 200, 200, 0.15),
    inset -2px -2px 0px 2px rgba(195, 195, 195, 0.15);
  height: 90px;
  min-width: 110%;
  width: auto;
  z-index: 1;
  border-radius: 10px 10px 10px 10px;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  object-fit: contain;
  display: flex;
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: center;
  margin: auto;
  /* margin-right: 50px; */
  /* margin-left: 100px; */
  margin-top: 75px;
  align-items: center;
  margin-left: -100px;
  box-sizing: border-box;
  align-content: center;
  transition: ease-in 0.5s;
  padding-left: 55px;
}

.home_queue:before {
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  -webkit-filter: blur(10px);
          filter: blur(10px);
  background: inherit;
}

.home_queue_invisible {
  margin-top: -100px;
  z-index: -10;
  height: 0px;
  transition: ease-out 0.5s;
}

.home_queue::-webkit-scrollbar {
  border-radius: 10px;
}

.home_queue::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px white;
  border-radius: 10px;
  scrollbar-width: none;
}

.home_queue::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.no_internet_img {
  margin: auto;
  margin-top: 100px;
  height: 350px;
  z-index: -10;
  width: 350px;
}
.home_desc {
  margin: auto;
  margin-top: 150px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  font-family: "Nunito", sans-serif;
}

.no_internet_txt {
  margin: auto;
  margin-top: -750px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  font-family: "Nunito", sans-serif;
}
.settings_icon_timeup {
  height: 25px;
  width: 25px;
  padding: 30px;
  margin: 5px;
  background: #ee0707; /* fallback for old browsers */

  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  font-size: 4vh;
  font-weight: 300;
  text-align: center;
  color: #26a0da;
  box-shadow: 10px 12px 18px -6px rgba(37, 37, 37, 0.753);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: 150ms;
  -webkit-transform: perspective(1000px) rotateY(0)
    translateY(0);
          transform: perspective(1000px) rotateY(0)
    translateY(0);
  -webkit-transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
          transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  /* display: flex;
  flex-direction: column; */
}

.home_btn {
  position: absolute;
  left: 0;
  margin-top: 200px;
  margin-left: 30px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  z-index: 5;
}
.home_btn_invisible {
  visibility: hidden;
}

.bell {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.bell .bell-top {
  width: 50px;
  height: 50px;
  background: #26a0da;
  width: 50px;
  height: 50px;
  border-radius: 50% 50% 0 0;
}
.bell .bell-top:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: inherit;
  top: -10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 50%;
}
.bell .bell-top:after {
  content: "";
  border-bottom: 10px solid #26a0da;
  border-left: 8px solid #26a0da;
  border-right: 8px solid #26a0da;
  height: 0;
  width: 50px;
  position: absolute;
  left: -8px;
  bottom: -10px;
}
.bell .bell-bot {
  width: 16px;
  height: 8px;
  background: #26a0da;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -23px;
  border-radius: 0 0 16px 16px;
}
.bell .bell-notification {
  background: #ff4c12;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -15px;
  color: black;
  font-family: "Open Sans";
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  opacity: 1;
}
.bell-top-anim {
  -webkit-animation: abanico 1000ms ease-in-out;
          animation: abanico 1000ms ease-in-out;
  -webkit-transform-origin: top;
          transform-origin: top;
}
.bell-bot-anim {
  -webkit-animation: badalo 1000ms ease-in-out;
          animation: badalo 1000ms ease-in-out;
}
.new-not {
  -webkit-animation: popup 500ms forwards;
          animation: popup 500ms forwards;
}
@-webkit-keyframes abanico {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  7.5% {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
  30% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
  45% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  58% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  87.5% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes abanico {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  7.5% {
    -webkit-transform: rotate(25deg);
            transform: rotate(25deg);
  }
  30% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
  45% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  58% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  70% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  87.5% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@-webkit-keyframes badalo {
  15% {
    -webkit-transform: translateX(-25px) rotate(7deg);
            transform: translateX(-25px) rotate(7deg);
  }
  32.5% {
    -webkit-transform: translateX(10px) rotate(-7deg);
            transform: translateX(10px) rotate(-7deg);
  }
  50% {
    -webkit-transform: translateX(-15px) rotate(7deg);
            transform: translateX(-15px) rotate(7deg);
  }
  65% {
    -webkit-transform: translateX(5px) rotate(-7deg);
            transform: translateX(5px) rotate(-7deg);
  }
  80% {
    -webkit-transform: translateX(-15px) rotate(7deg);
            transform: translateX(-15px) rotate(7deg);
  }
  90% {
    -webkit-transform: translateX(0px) rotate(-7deg);
            transform: translateX(0px) rotate(-7deg);
  }
}
@keyframes badalo {
  15% {
    -webkit-transform: translateX(-25px) rotate(7deg);
            transform: translateX(-25px) rotate(7deg);
  }
  32.5% {
    -webkit-transform: translateX(10px) rotate(-7deg);
            transform: translateX(10px) rotate(-7deg);
  }
  50% {
    -webkit-transform: translateX(-15px) rotate(7deg);
            transform: translateX(-15px) rotate(7deg);
  }
  65% {
    -webkit-transform: translateX(5px) rotate(-7deg);
            transform: translateX(5px) rotate(-7deg);
  }
  80% {
    -webkit-transform: translateX(-15px) rotate(7deg);
            transform: translateX(-15px) rotate(7deg);
  }
  90% {
    -webkit-transform: translateX(0px) rotate(-7deg);
            transform: translateX(0px) rotate(-7deg);
  }
}
@-webkit-keyframes popup {
  0% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes popup {
  0% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.boxes:before {
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
          backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
}
.boxes {
  margin: 40px;
  margin-top: 20px !important;
  height: 55vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0.05);
  transition: ease all 0.2s;
  width: 70vw;
}
.box_content {
  padding: 5px;
}
.boxes:hover {
  -webkit-transform: scale(1.2) translateY(-20px);
          transform: scale(1.2) translateY(-20px);
}

div.boxes h2 {
  background: #08b;
  background-image: radial-gradient(transparent 30%, rgba(0, 0, 0, 0.2));
  border: 0 solid rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.2);
}

.boxes h2 {
  border-width: 1px 1px 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 4px 30px;
  position: absolute;
  right: -14px;
  top: 12px;
}
.boxes h2:after {
  border-width: 7px;
  border-style: solid;
  border-color: #134 transparent transparent #134;
  bottom: -15px;
  content: "";
  position: absolute;
  right: -1px;
}


.checkbox2 {
  display: none;
}
label {
  margin-left: 50px;
}
.checkbox2+ label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px "Open Sans", Arial, sans-serif;
  color: #000;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.home_body {
  margin-top: 100px !important;
  height: 100%;
  margin: 0;
  background: #363636;
  display: flex;
  align-items: center;
}
.checkbox2 + label:last-child {
  margin-bottom: 0;
}

.checkbox2 + label:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border: 3px solid #03a9f4;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  transition: all 0.12s, border-color 0.08s;
}

.checkbox2:checked + label:before {
  width: 5px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cross_line {
  display: inline-block;
  position: relative;
  padding: 0.25em 1em;
  border-top: solid 2px black;
  border-bottom: solid 2px black;
  text-decoration: none;
  font-weight: bold;
  color: #03a9f4;
}
.cross_line:before,
.cross_line:after {
  content: "";
  position: absolute;
  top: -7px;
  width: 2px;
  height: calc(100% + 14px);
  background-color: black;
  transition: 0.3s;
}
.cross_line:before {
  left: 7px;
}
.cross_line:after {
  right: 7px;
}
.cross_line:hover:before {
  top: 0px;
  left: 0;
  height: 100%;
}
.cross_line:hover:after {
  top: 0px;
  right: 0;
  height: 100%;
}
.accept {
  position: fixed;
  top: 0;
  margin-top: 80px;
  right: 0;
  margin-right: 40px;
}
.home_grid {
  background: linear-gradient(
      to top right,
      rgba(100, 213, 251, 0.3),
      rgba(228, 166, 249, 0.5)
    )
    fixed;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#cbccc8');
  background-attachment: fixed;
  height: 100% !important;
  width: 110% !important;
  overflow: auto;
  position: fixed;
  min-height: 110%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 55px !important;
  margin-bottom: 70% !important;
  margin-left: -30px !important;
  /* margin: 10px; */
  justify-content: center;
  text-align: center;
  /* margin: auto; */
  -webkit-animation: backInDown;
          animation: backInDown; /* referring directly to the animation's @keyframe declaration */
  -webkit-animation-duration: 2s;
          animation-duration: 2s; /* don't forget to set a duration! */
  scroll-padding: 400px;
  padding-bottom: 160px !important;
  z-index: -2;
  padding-top: 115px !important;
  /* padding-right: 5px; */
  /* padding-left: 20px; */
}

.home_grid::-webkit-scrollbar-track {
  scrollbar-width: none;
  box-shadow: inset 0 0 4px white;
}

.home_grid > div {
  flex-basis: calc(25% - 40px);
}
.change_black{
  color: black;
}
.change{
  color : #3c9cc2;
  size: 50px;
}
body {
  font-family: 'Nunito', sans-serif;
}

.form{
width: auto;
height: auto;
background-color: rgb(255, 255, 255);
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
background-color: rgb(255, 255, 255);
overflow: auto;
margin-top: -10%;
}

.profile_button{
 margin-top: 25%;
 margin-bottom: 20px;
}

input{
  text-align: left !important; 
}

.update
{
  font-size: 22px;
	font-weight: 100;
	line-height: 29px;
	letter-spacing: 0.6px;
  color : #3c9cc2;
}
* {
  margin: 0;
  font-family: "Nunito", sans-serif;
}

.settings_table_count {
  margin-top: 120px;
  margin-left: 10px;
  font-weight: 600;
  color: #0d5a7e;
  font-size: 25px;
}
.settings_nav {
  width: 280px;
  height: 205px !important;
  border: black solid 2px;
  right: 0;
  position: absolute;
  object-fit: contain;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-y: auto;
  justify-content: center;
  margin: auto;
  margin-right: 10px;
  margin-top: 20px;
  padding: 0 10px 0 10px;
}

.settings_icon {
  height: 25px;
  width: 25px;
  padding: 30px;
  margin: 5px;
  background: #314755; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  font-size: 4vh;
  font-weight: 300;
  text-align: center;
  color: white;
  box-shadow: 10px 12px 18px -6px rgba(37, 37, 37, 0.753);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: 150ms;
  -webkit-transform: perspective(1000px) rotateY(0)
    translateY(0);
          transform: perspective(1000px) rotateY(0)
    translateY(0);
  -webkit-transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
          transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  /* display: flex;
  flex-direction: column; */
}
.settings_table_no {
  z-index: 100;
  position: absolute;
  bottom: 0px;
  margin-bottom: 8px;
  font-size: small;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  color: white;
  max-width: 200px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 100px;
  max-height: 200px;
  object-fit: contain;
  overflow-y: scroll;
  background-color: #fff;
  margin-left: 30px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #000;
  padding: 10px;
  font-weight: 500;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content a:hover {
  color: #079aa5;
  font-weight: 700;
  transition: ease-in 150ms;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* circular menu */

/* input {position: absolute; display: none} */ /*Do not call segments   by generic name  use diiferent classnames*/

.checkbox1 {
  position: absolute;
  display: none;
}
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.menu {
  margin: 0 auto;
  margin-left: 46%;
  margin-top: 190px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.btn2 > button {
  background: #314755; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border: 0px;
  width: 220px;
  height: 110px;
  border-radius: 70px;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
}

.btn2 {
  width: 115px;
  height: 115px;
  border-radius: 70px;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.btn2 {
  background: #314755; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn2:not(:first-child) {
  opacity: 0;
  z-index: -2;
  transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
}

.btn2:nth-child(2) {
  top: 0px;
  transition-delay: 0s;
}
.btn2:nth-child(3) {
  top: 0px;
  left: 0px;
  transition-delay: 0.1s;
}
.btn2:nth-child(4) {
  left: 0px;
  transition-delay: 0.2s;
}

input#toggle:checked ~ #show-menu .btn2:nth-child(2) {
  top: 210px;
  opacity: 1;
}

input#toggle:checked ~ #show-menu .btn2:nth-child(3) {
  top: 100px;
  left: -120px;
  opacity: 1;
}

input#toggle:checked ~ #show-menu .btn2:nth-child(4) {
  top: 100px;
  left: 130px;
  opacity: 1;
}

.menuBtn,
.closeBtn {
  position: absolute;
  transition: all 0.3s ease;
}

.closeBtn {
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  opacity: 0;
}

input#toggle:checked ~ #show-menu .btn2 .menuBtn {
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  opacity: 0;
}

input#toggle:checked ~ #show-menu .btn2 .closeBtn {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  opacity: 1;
}

*{
    margin: 0;
    font-family: "Nunito", sans-serif !important  ;
    
}

.support_icon{
    margin:auto;
    margin-top: 5px;
    height: 180px;
    width: 180px;
    color: #2da1b1;

}
.support_text{
    margin-top: 130px;
    line-height: normal;
    text-align: center;
    font-size: large;
    font-weight: 700;
    align-content: center;
    justify-content: center;
}
.email{
    border-radius: 30px;
	border: 1px solid #449ff5;
	background-color: #2da1b1;
	color: white;
	font-size: 20px;
	font-weight: 700;
	padding: 5px 10px !important;
	letter-spacing: 1px;
	/* text-transform: uppercase; */
    transition: -webkit-transform 80ms ease-in !important ;
    transition: transform 80ms ease-in !important ;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in !important ;
	font-family: 'Nunito', sans-serif; 
	cursor: pointer; 
}
.description{
    margin-top : 10px;
    font-size: 20px;
	font-weight: 500;
	line-height: 29px;
	letter-spacing: 0.4px;
}
.password_reset{
	justify-content: center;
	align-items: center;
	text-align: center;
}
.fp{
    width: 45%;
	height: 55%;
	background-color: rgb(255, 255, 255);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
    text-align: center;
    display: flex;
	align-items: center;
	justify-content: center;
    flex-direction: column;
	margin-top: 35vh;
	background-color: rgb(255, 255, 255);
}

.password_reset_email{
	height:13%;
	margin:5px;
    text-align: center;
	background-color: rgb(236, 235, 235);
	width: auto;
}

button{
	border-radius: 20px;
	border: 1px solid #449ff5;
	background-color: #2da1b1;
	color: #FFFFFF;
	font-size: 20px;
	font-weight: 500;
	padding: 12px 45px !important;
	letter-spacing: 1px;
	/* text-transform: uppercase; */
    transition: -webkit-transform 80ms ease-in !important ;
    transition: transform 80ms ease-in !important ;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in !important ;
	font-family: 'Nunito', sans-serif; 
	cursor: pointer;   

}

a1{
	color: rgb(255, 255, 255);
	font-size: 22px;
	text-decoration: none;
	margin: 15px 0;
}
.fp_logo{

	object-fit: contain;
	width: 150px;
	height: 80px;
	margin-top: -4px;
	justify-content: center;
	align-items: center;
	position: fixed;
	margin-left: 45%;

}
.fp_header{
	top: 0;
	background-color: black;
	display: flex;
	height: 75px;
	align-items: center;
	width: 100%;
	position: fixed;
	justify-content: space-between;
	z-index: 100;
}

.reset_desc{
    font-size: 22px;
	font-family: 'Nunito', sans-serif;
	margin:5%;
	margin-top: -20%;
	font-weight: 100;
	line-height: 30px;
}

.reset_msg{
	font-size: 16px;
	margin-top: 20px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 1px;	
}

.pf_logo{
	height:6%;
	width:6%;
	margin-top:5%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
