*{
	box-sizing: border-box;
}

.logo{
    height: 100px;
    margin: auto;
    margin-left: 45%;
    z-index: -1;
}

h1 {
	font-weight: bold;
	margin: 0;
}

h2 {
	text-align: center;
}

p {
	font-size: 22px;
	font-weight: 100;
	line-height: 29px;
	letter-spacing: 0.4px;
	margin: 20px 0 30px;
}

span {
	font-size: 22px;
}

a {
	color: rgb(6, 14, 20);
	font-size: 22px;
	text-decoration: none;
	margin: 15px 0;
}


button {
	border-radius: 20px;
	border: 1px solid #449ff5;
	background-color: #2da1b1;
	color: #FFFFFF;
	font-size: 20px;
	font-weight: 500;
	padding: 12px 45px !important;
	letter-spacing: 1px;
	/* text-transform: uppercase; */
    transition: transform 80ms ease-in !important ;
	font-family: 'Nunito', sans-serif; 
	cursor: pointer;   
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
}

button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

input {
	background-color: #eee !important;
	border-radius: 20px !important;
	border: 1px solid #ffffff !important;
	padding: 12px 15px !important;
	margin: 8px 0 !important;
    width: auto ;
	font-family: 'Nunito', sans-serif !important;
    font-size: 3vh !important;
}
.Signup_button{
	margin-top: 5px;
}

.form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	overflow: hidden;
	z-index: 5;
	animation: show 0.6s;
}

.overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right-panel-active .overlay-container{
	transform: translateX(-100%);
}

.overlay {
	background: #358aa0;
	background: -webkit-linear-gradient(to right, #3c9cc2, #4690f1);
	background: linear-gradient(to right, #3ca2af, #309dbe);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
	transform: translateX(50%);
}

.overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay-left {
	transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.overlay-right {
	right: 0;
	transform: translateX(0);
}

.container.right-panel-active .overlay-right {
	transform: translateX(20%);
}


@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');
* {
  padding: 0px;
  margin: 0px;
}


ul.social {
    position: relative;
    display: flex !important;
    transform: rotate(25deg) skew(deg);
    transform-style: preserve-3d;
 }
 ul.social li.social {
   position: relative;
   list-style: none;
   width: 50px;
   height: 50px;
   margin: -20px 22px;
   margin-top: -10px;
   cursor: pointer;
   border-radius: 50px;
 }
.error{
	color: red !important;
}
 ul.social li.social span{
   position: absolute;
   top: 0;
   /* left: 0; */
   width: 100% ;
   height: 100%;
   display: flex !important;
   /* background: #2da1b1; */
   justify-content: center;
   align-items: center;
   color: #fff;
   font-size: 30px !important;
   transition: 1.5s ease-out;
   margin-left: 0px ;
   margin-top: 5px;
   border-radius: 10px;
 }
 ul.social li.social:hover span {
   z-index: 1000;
   transition: .3s;
   color: #fff;
   /* box-shadw: -1px 1px 1px rgba(0, 0, 0, .5) !important; */
 }
 
 
 /* ul.social li.social:hover span:nth-child(5){
   transform: translate(20px, -20px);
   background: #9e2166;
   opacity: 1;
   border-radius: 50px;
 } */
 /* ul.social li.social:hover span:nth-child(4){
   transform: translate(30px, -30px);
   opacity: .8;
 }
 ul.social li.social:hover span:nth-child(3){
   transform: translate(20px, -20px);
   opacity: .6;
 } */
 /* ul.social li.social:hover span:nth-child(2){
   transform: translate(10px, -10px);
   opacity: .8 !important;
   border-radius: 50px;
 } */
 /* ul.social li.social:hover span:nth-child(1){
   transform: translate(5px, -5px);
   opacity: .2;
 } */
 /* ul.social li.social:nth-child(1):hover span{
   background:	#4285F4;
   opacity: 1;
   border-radius: 10px;
 }
 ul.social li.social:nth-child(2):hover span{
   background: #3b5998;
   opacity: 1;
   border-radius: 10px;
 } */
 /* ul.social li.social:nth-child(3):hover span{
	background: #f09433; 
	background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
	background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
	background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
	opacity: 1;
	border-radius: 10px;
  } */