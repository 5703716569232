.password_reset{
	justify-content: center;
	align-items: center;
	text-align: center;
}
.fp{
    width: 45%;
	height: 55%;
	background-color: rgb(255, 255, 255);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
    text-align: center;
    display: flex;
	align-items: center;
	justify-content: center;
    flex-direction: column;
	margin-top: 35vh;
	background-color: rgb(255, 255, 255);
}

.password_reset_email{
	height:13%;
	margin:5px;
    text-align: center;
	background-color: rgb(236, 235, 235);
	width: auto;
}

button{
	border-radius: 20px;
	border: 1px solid #449ff5;
	background-color: #2da1b1;
	color: #FFFFFF;
	font-size: 20px;
	font-weight: 500;
	padding: 12px 45px !important;
	letter-spacing: 1px;
	/* text-transform: uppercase; */
    transition: transform 80ms ease-in !important ;
	font-family: 'Nunito', sans-serif; 
	cursor: pointer;   

}

a1{
	color: rgb(255, 255, 255);
	font-size: 22px;
	text-decoration: none;
	margin: 15px 0;
}
.fp_logo{

	object-fit: contain;
	width: 150px;
	height: 80px;
	margin-top: -4px;
	justify-content: center;
	align-items: center;
	position: fixed;
	margin-left: 45%;

}
.fp_header{
	top: 0;
	background-color: black;
	display: flex;
	height: 75px;
	align-items: center;
	width: 100%;
	position: fixed;
	justify-content: space-between;
	z-index: 100;
}

.reset_desc{
    font-size: 22px;
	font-family: 'Nunito', sans-serif;
	margin:5%;
	margin-top: -20%;
	font-weight: 100;
	line-height: 30px;
}

.reset_msg{
	font-size: 16px;
	margin-top: 20px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 1px;	
}

.pf_logo{
	height:6%;
	width:6%;
	margin-top:5%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}