* {
  margin: 0;
  font-family: "Nunito", sans-serif !important  ;
}
body {
  text-align: center;
  overflow: hidden;
  font-family: "Nunito", sans-serif;
  background: rgb(255, 255, 255); /* fallback for old browsers */
}
.order {
  margin-top: 15px;
}
.card {
  margin: 20px !important;
  height: 280px !important;
  width: 280px !important;
  background: #314755; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #26a0da,
    #346280
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  font-size: 4vh;
  font-weight: 300;
  text-align: center;
  color: white;
  -webkit-box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 10px 12px 18px -6px rgba(37, 37, 37, 0.753);
  transform-style: preserve-3d;
  transition: 150ms;
  transform: perspective(10000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  /* display: flex;
  flex-direction: column; */
}
.card > img {
  width: 285px;
  border-radius: 50%;
  margin-top: -2px;
  margin-left: -1px;
  object-fit: cover;
  -webkit-box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 10px 12px 18px -6px rgba(37, 37, 37, 0.753);
  z-index: -1;
}

.card:hover {
  --translate-y: -8px;
  box-shadow: 0 0 5px 1px rgb(165, 166, 167) !important;
}

.card.flip {
  --rotate-y: 180deg;
  background: #314755; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #26a0da,
    #346280
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  color: #2d5d69;
  font-size: 4vh;
  font-weight: 300;
  font-family: "Nunito", sans-serif;
  text-align: center;
  font-weight: bold;
}

.card .front {
  border-radius: 50%;
  object-fit: cover;
  width: 55%;
  z-index: -1;
  margin-top: -32px;
  text-align: center;
  justify-content: center;
  position: fixed;
}

.card .back {
  border-radius: 50%;
  object-fit: cover;
  position: fixed;
  width: 115%;
  height: 100%;
  z-index: -1;
  margin-left: -10px;
  margin-top: 0px;
  object-fit: contain !important;
  /* margin-bottom: 10px; */
  text-align: center;
}

.card .back {
  transform: rotateY(180deg);
}

.card:hover {
  --translate-y: -1px;
  box-shadow: 0 0 5px 1px rgb(165, 166, 167) !important;
}

.card .front,
.card .back {
  position: absolute;
  padding: 1rem;
  backface-visibility: hidden;
}

.cards_container {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.grid {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  height: 100% !important;
  width: 100% !important;
  overflow: auto;
  position: fixed;
  min-height: 110%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 55px !important;
  margin-bottom: 70% !important;
  margin-left: -10px !important;
  /* margin: 10px; */
  justify-content: center;
  text-align: center;
  /* margin: auto; */
  animation: backInDown; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
  scroll-padding: 400px;
  padding-bottom: 160px !important;
  z-index: -2;
  padding-top: 115px !important;
  /* padding-right: 5px; */
  /* padding-left: 20px; */
}

.grid::-webkit-scrollbar-track {
  scrollbar-width: none;
  box-shadow: inset 0 0 4px white;
}

.grid > div {
  flex-basis: calc(25% - 40px);
}

.details {
  color: rgb(0, 0, 0);
  position: relative;
  margin-top: 20px;
}

.number {
  z-index: 2;
  bottom: 0px;
  /* position: fixed; */
  color: white;
}

.msg {
  max-width: 210px;
  min-height: 1px;
  margin-top: 120px;
  margin-left: 38px;
  font-family: "Nunito", sans-serif;
  /* object-fit: contain; */
  color: #ffffff;
  z-index: 2;
  font-size: 4vh;
  text-align: center;
  justify-content: center;
  align-content: center;
  font-weight: 600;
}

.img_number {
  position: absolute;
  bottom: 0;
  color: white;
  font-weight: 700;
  font-size: 4.1vh;
}

.hidden {
  visibility: hidden;
}
.visible {
  visibility: visible;
}

.delete_card {
  margin: 20px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  height: 282px;
  width: 87%;
  background: rgba(20, 59, 97, 0.993);
  background: linear-gradient(to bottom, #1d8bbe, #042f49);
  border-radius: 50% !important;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 4vh;
  font-weight: 300;
  text-align: center;
  margin-top: -1px;
  margin-left: 17px;
  z-index: 3;
}

h2 {
  font-size: 2.3vh;
  font-weight: 600;
  min-width: 210px;
  margin-left: -36px;
  position: fixed;
  margin-top: -45px;
}
.delete_card.front {
  position: fixed;
  padding: 1rem;
  backface-visibility: hidden;
  border-radius: 50%;
  object-fit: cover;
  width: 37%;
  z-index: 10;
}

.delete_card.back {
  /* position: absolute; */
  padding: 1rem;
  backface-visibility: hidden;
  border-radius: 50%;
  /* object-fit: cover; */
  width: 38%;
  z-index: 10;
  background-color: white;
}

.delete_icons {
  margin-left: -105px;
  z-index: 10;
  color: #e0e0e0;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
  /* display: table-row; */
  display: flex;
  position: fixed;
}

.del_text {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  align-content: space-between;
  margin-left: -10px;
  color: #fcfcfc;
  margin-top: 5px;
  position: fixed;
}

.delete_img_card {
  z-index: 1;
  margin-left: -218px;
  margin-top: 142px;
}
.outer {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}
.outer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
.outer .inner {
  z-index: 2;
}

/* Style buttons */
.btn {
  /* height: 0%; */
  width: 30%;
  display: flex;
  flex-direction: row;
  background: none;
  align-items: center;
  margin-left: 8px;
}

.multiple_hidden {
  visibility: hidden;
}
.multiple_visible {
  visibility: visible;
  font-size: 12px;
}
.timer {
  margin-top: -20px;
  margin-left: -7px;
}
