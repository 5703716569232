* {
  margin: 0;
  font-family: "Nunito", sans-serif;
}

.settings_table_count {
  margin-top: 120px;
  margin-left: 10px;
  font-weight: 600;
  color: #0d5a7e;
  font-size: 25px;
}
.settings_nav {
  width: 280px;
  height: 205px !important;
  border: black solid 2px;
  right: 0;
  position: absolute;
  object-fit: contain;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-y: auto;
  justify-content: center;
  margin: auto;
  margin-right: 10px;
  margin-top: 20px;
  padding: 0 10px 0 10px;
}

.settings_icon {
  height: 25px;
  width: 25px;
  padding: 30px;
  margin: 5px;
  background: #314755; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #26a0da,
    #346280
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  font-size: 4vh;
  font-weight: 300;
  text-align: center;
  color: white;
  -webkit-box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 10px 12px 18px -6px rgba(37, 37, 37, 0.753);
  transform-style: preserve-3d;
  transition: 150ms;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  /* display: flex;
  flex-direction: column; */
}
.settings_table_no {
  z-index: 100;
  position: absolute;
  bottom: 0px;
  margin-bottom: 8px;
  font-size: small;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  color: white;
  max-width: 200px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 100px;
  max-height: 200px;
  object-fit: contain;
  overflow-y: scroll;
  background-color: #fff;
  margin-left: 30px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #000;
  padding: 10px;
  font-weight: 500;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content a:hover {
  color: #079aa5;
  font-weight: 700;
  transition: ease-in 150ms;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* circular menu */

/* input {position: absolute; display: none} */ /*Do not call segments   by generic name  use diiferent classnames*/

.checkbox1 {
  position: absolute;
  display: none;
}
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.menu {
  margin: 0 auto;
  margin-left: 46%;
  margin-top: 190px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  align-items: center;
  justify-content: center;
}

.btn2 > button {
  background: #314755; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #26a0da,
    #346280
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border: 0px;
  width: 220px;
  height: 110px;
  border-radius: 70px;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
}

.btn2 {
  width: 115px;
  height: 115px;
  border-radius: 70px;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.btn2 {
  background: #314755; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #26a0da,
    #346280
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #26a0da,
    #314755
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn2:not(:first-child) {
  opacity: 0;
  z-index: -2;

  -webkit-transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
}

.btn2:nth-child(2) {
  top: 0px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.btn2:nth-child(3) {
  top: 0px;
  left: 0px;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.btn2:nth-child(4) {
  left: 0px;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

input#toggle:checked ~ #show-menu .btn2:nth-child(2) {
  top: 210px;
  opacity: 1;
}

input#toggle:checked ~ #show-menu .btn2:nth-child(3) {
  top: 100px;
  left: -120px;
  opacity: 1;
}

input#toggle:checked ~ #show-menu .btn2:nth-child(4) {
  top: 100px;
  left: 130px;
  opacity: 1;
}

.menuBtn,
.closeBtn {
  position: absolute;
  transition: all 0.3s ease;
}

.closeBtn {
  transform: translateY(50px);
  opacity: 0;
}

input#toggle:checked ~ #show-menu .btn2 .menuBtn {
  transform: translateY(-50px);
  opacity: 0;
}

input#toggle:checked ~ #show-menu .btn2 .closeBtn {
  transform: translateY(0px);
  opacity: 1;
}
