*{
    margin: 0;
    font-family: "Nunito", sans-serif !important  ;
    
}

.support_icon{
    margin:auto;
    margin-top: 5px;
    height: 180px;
    width: 180px;
    color: #2da1b1;

}
.support_text{
    margin-top: 130px;
    line-height: normal;
    text-align: center;
    font-size: large;
    font-weight: 700;
    align-content: center;
    justify-content: center;
}
.email{
    border-radius: 30px;
	border: 1px solid #449ff5;
	background-color: #2da1b1;
	color: white;
	font-size: 20px;
	font-weight: 700;
	padding: 5px 10px !important;
	letter-spacing: 1px;
	/* text-transform: uppercase; */
    transition: transform 80ms ease-in !important ;
	font-family: 'Nunito', sans-serif; 
	cursor: pointer; 
}
.description{
    margin-top : 10px;
    font-size: 20px;
	font-weight: 500;
	line-height: 29px;
	letter-spacing: 0.4px;
}