
body{
    margin:20px;
}

.Error_Home{
	border-radius: 20px;
	border: 1px solid #449ff5;
	background-color: #128cbd;
	font-size: 20px;
	font-weight: 600;
	padding: 12px 30px !important;
	letter-spacing: 1px;
	/* text-transform: uppercase; */
    transition: transform 80ms ease-in ;
	font-family: 'Nunito', sans-serif; 
	cursor: pointer;  
    margin-top: -30%;
    z-index: 100;
}

.Error_Home > button{
	margin-top: -30%;
}

.error_main{
  z-index: -100;
  height: 400px;
  object-fit: contain;
  margin-top: 40px;
}

h2{
    text-align: center;
    align-content: center;
    justify-content: center;
}