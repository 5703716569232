.home_queue {
  /* background: rgba(142, 176, 228, 0.3); */
  background: none;
  backdrop-filter: blur(0px);

  box-shadow: 8px 8px 5px rgba(0, 0, 0, 0.3),
    inset 5px -5px 0px 5px rgba(200, 200, 200, 0.15),
    inset -2px -2px 0px 2px rgba(195, 195, 195, 0.15);
  height: 90px;
  min-width: 110%;
  width: auto;
  z-index: 1;
  border-radius: 10px 10px 10px 10px;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  object-fit: contain;
  display: flex;
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: center;
  margin: auto;
  /* margin-right: 50px; */
  /* margin-left: 100px; */
  margin-top: 75px;
  align-items: center;
  margin-left: -100px;
  box-sizing: border-box;
  align-content: center;
  transition: ease-in 0.5s;
  padding-left: 55px;
}

.home_queue:before {
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: blur(10px);
  background: inherit;
}

.home_queue_invisible {
  margin-top: -100px;
  z-index: -10;
  height: 0px;
  transition: ease-out 0.5s;
}

.home_queue::-webkit-scrollbar {
  border-radius: 10px;
}

.home_queue::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px white;
  border-radius: 10px;
  scrollbar-width: none;
}

.home_queue::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.no_internet_img {
  margin: auto;
  margin-top: 100px;
  height: 350px;
  z-index: -10;
  width: 350px;
}
.home_desc {
  margin: auto;
  margin-top: 150px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
  transition: transform 80ms ease-in;
  font-family: "Nunito", sans-serif;
}

.no_internet_txt {
  margin: auto;
  margin-top: -750px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
  transition: transform 80ms ease-in;
  font-family: "Nunito", sans-serif;
}
.settings_icon_timeup {
  height: 25px;
  width: 25px;
  padding: 30px;
  margin: 5px;
  background: #ee0707; /* fallback for old browsers */

  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  font-size: 4vh;
  font-weight: 300;
  text-align: center;
  color: #26a0da;
  -webkit-box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 10px 12px 18px -6px rgba(37, 37, 37, 0.753);
  transform-style: preserve-3d;
  transition: 150ms;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  /* display: flex;
  flex-direction: column; */
}

.home_btn {
  position: absolute;
  left: 0;
  margin-top: 200px;
  margin-left: 30px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  z-index: 5;
}
.home_btn_invisible {
  visibility: hidden;
}

.bell {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bell .bell-top {
  width: 50px;
  height: 50px;
  background: #26a0da;
  width: 50px;
  height: 50px;
  border-radius: 50% 50% 0 0;
}
.bell .bell-top:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: inherit;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}
.bell .bell-top:after {
  content: "";
  border-bottom: 10px solid #26a0da;
  border-left: 8px solid #26a0da;
  border-right: 8px solid #26a0da;
  height: 0;
  width: 50px;
  position: absolute;
  left: -8px;
  bottom: -10px;
}
.bell .bell-bot {
  width: 16px;
  height: 8px;
  background: #26a0da;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -23px;
  border-radius: 0 0 16px 16px;
}
.bell .bell-notification {
  background: #ff4c12;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -15px;
  color: black;
  font-family: "Open Sans";
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  opacity: 1;
}
.bell-top-anim {
  animation: abanico 1000ms ease-in-out;
  transform-origin: top;
}
.bell-bot-anim {
  animation: badalo 1000ms ease-in-out;
}
.new-not {
  animation: popup 500ms forwards;
}
@keyframes abanico {
  0% {
    transform: rotate(0deg);
  }
  7.5% {
    transform: rotate(25deg);
  }
  30% {
    transform: rotate(-25deg);
  }
  45% {
    transform: rotate(15deg);
  }
  58% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(5deg);
  }
  87.5% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes badalo {
  15% {
    transform: translateX(-25px) rotate(7deg);
  }
  32.5% {
    transform: translateX(10px) rotate(-7deg);
  }
  50% {
    transform: translateX(-15px) rotate(7deg);
  }
  65% {
    transform: translateX(5px) rotate(-7deg);
  }
  80% {
    transform: translateX(-15px) rotate(7deg);
  }
  90% {
    transform: translateX(0px) rotate(-7deg);
  }
}
@keyframes popup {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.boxes:before {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px) saturate(100%) contrast(45%) brightness(130%);
}
.boxes {
  margin: 40px;
  margin-top: 20px !important;
  height: 55vh;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0.05);
  transition: ease all 0.2s;
  width: 70vw;
}
.box_content {
  padding: 5px;
}
.boxes:hover {
  transform: scale(1.2) translateY(-20px);
}

div.boxes h2 {
  background: #08b;
  background-image: radial-gradient(transparent 30%, rgba(0, 0, 0, 0.2));
  border: 0 solid rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.2);
}

.boxes h2 {
  border-width: 1px 1px 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 4px 30px;
  position: absolute;
  right: -14px;
  top: 12px;
}
.boxes h2:after {
  border-width: 7px;
  border-style: solid;
  border-color: #134 transparent transparent #134;
  bottom: -15px;
  content: "";
  position: absolute;
  right: -1px;
}


.checkbox2 {
  display: none;
}
label {
  margin-left: 50px;
}
.checkbox2+ label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  font: 14px/20px "Open Sans", Arial, sans-serif;
  color: #000;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.home_body {
  margin-top: 100px !important;
  height: 100%;
  margin: 0;
  background: #363636;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.checkbox2 + label:last-child {
  margin-bottom: 0;
}

.checkbox2 + label:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border: 3px solid #03a9f4;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.6;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
}

.checkbox2:checked + label:before {
  width: 5px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cross_line {
  display: inline-block;
  position: relative;
  padding: 0.25em 1em;
  border-top: solid 2px black;
  border-bottom: solid 2px black;
  text-decoration: none;
  font-weight: bold;
  color: #03a9f4;
}
.cross_line:before,
.cross_line:after {
  content: "";
  position: absolute;
  top: -7px;
  width: 2px;
  height: -webkit-calc(100% + 14px);
  height: calc(100% + 14px);
  background-color: black;
  transition: 0.3s;
}
.cross_line:before {
  left: 7px;
}
.cross_line:after {
  right: 7px;
}
.cross_line:hover:before {
  top: 0px;
  left: 0;
  height: 100%;
}
.cross_line:hover:after {
  top: 0px;
  right: 0;
  height: 100%;
}
.accept {
  position: fixed;
  top: 0;
  margin-top: 80px;
  right: 0;
  margin-right: 40px;
}
.home_grid {
  background: linear-gradient(
      to top right,
      rgba(100, 213, 251, 0.3),
      rgba(228, 166, 249, 0.5)
    )
    fixed;
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#cbccc8');
  background-attachment: fixed;
  height: 100% !important;
  width: 110% !important;
  overflow: auto;
  position: fixed;
  min-height: 110%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 55px !important;
  margin-bottom: 70% !important;
  margin-left: -30px !important;
  /* margin: 10px; */
  justify-content: center;
  text-align: center;
  /* margin: auto; */
  animation: backInDown; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
  scroll-padding: 400px;
  padding-bottom: 160px !important;
  z-index: -2;
  padding-top: 115px !important;
  /* padding-right: 5px; */
  /* padding-left: 20px; */
}

.home_grid::-webkit-scrollbar-track {
  scrollbar-width: none;
  box-shadow: inset 0 0 4px white;
}

.home_grid > div {
  flex-basis: calc(25% - 40px);
}
.change_black{
  color: black;
}
.change{
  color : #3c9cc2;
  size: 50px;
}